// Modify the structure of .tileGallery to meet the needs of GlideJS
export default function buildCarouselHtml(targetContainer, photos) {
    let carouselHtml = '';

    const galleryControls = 
        `<div class="glide__arrows" data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
        </div>`;

    carouselHtml += `<div class="glide__track" data-glide-el="track">`;
    carouselHtml += `<ul class="glide__slides">`;

    photos.forEach((photo) => {
        carouselHtml += `<li class="glide__slide"> ${photo.innerHTML} </li>`;
    });

    carouselHtml += `</ul>`;
    carouselHtml += `</div>`;
    carouselHtml += galleryControls;

    // console.log(carouselHtml);

    targetContainer.innerHTML = carouselHtml;
    targetContainer.classList.add('glide');
}
