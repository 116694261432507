import buildCarouselHtml from 'views/carousel/buildCarouselHtml';
import activateTileGalleryGlide from 'views/carousel/activateTileGalleryGlide';

// Set MutationObserver to watch for modifications to .tileGallery
function activateOnDomModification() {
    let target = document.querySelector('.tileGallery');

    let config = {
        subtree:true, 
        attributes: false, 
        childList: true, 
        characterData: false
    };

    let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            activateTileGalleryGlide();
            observer.disconnect();
        });
    });
    observer.observe(target, config);
}


// Set variables and triggers for mobile GlideJS carousel
// --------------
const viewportWidthSm = 680;
const galleryContainer = document.querySelector('.tileGallery');
const tileGalleryPhotos = document.querySelectorAll('.tileGallery-photo');

// Conditions to fire:
//     There is more than one result in the tileGallery photos NodeList
//     The viewport is narrower than the defined viewport width
if (tileGalleryPhotos.length > 1 && window.innerWidth < viewportWidthSm) {
    activateOnDomModification();
    buildCarouselHtml(galleryContainer, tileGalleryPhotos);
}
