import Glide from '@glidejs/glide';

// When all conditions are met, activate GlideJS carousel
export default function activateTileGalleryGlide() {
    let glide = new Glide('.glide', {
        keyboard: true,
        type: 'carousel'  
    });

    glide.mount();
}